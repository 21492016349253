<template>
    <OModal ref="modal" @shown="updateModalFields" @hidden="clearAllFields">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('Add New Cost Centre') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-4 form-group">
                            <label>{{ $t("Legal Entity") }}</label>
                            <input class="form-control" v-model="legalEntity.name" disabled/>
                        </div>
                        <div class="col-4 form-group">
                            <label>{{ $t("Name") }}</label>
                            <input class="form-control" v-model="person.ccName" />
                        </div>
                        <div class="col-4 form-group">
                            <label>{{ $t("Person") }}</label>
                            <ODataLookup class="form-control" :dataObject="dsPersonLkp" v-model="person.name" :bind="sel=>{person.ccName=sel.MarketMakerLink;person.id=sel.ID;person.name=sel.Name}">
                                <OColumn field="LastName" width="150" :headerName="$t('Last Name')"/>
                                <OColumn field="FirstName" width="150" :headerName="$t('First Name')"/>
                                <OColumn field="MobileNo" width="150" :headerName="$t('Mobile No.')"/>
                            </ODataLookup>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <span v-if="!dsLegalEntities.current.ID" class="text-sm text-danger">{{ $t("Must set a valid Legal Entity") }}</span>
                    <button type="button" class="btn btn-primary" :disabled="!dsLegalEntities.current.ID || isLoading" @click="createNewCostCentre">
                        <div v-if="isLoading" class="spinner-border spinner-border-sm" style="0.35rem" role="status">
                            <span class="visually-hidden">{{ $t('Loading...') }}</span>
                        </div>
                        {{ $t("Add Cost Centre") }}
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("Cancel") }}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
    import { ref, defineExpose, onMounted } from 'vue';
    import { getOrCreateDataObject, getOrCreateProcedure } from 'o365.vue.ts';
    import context from 'o365.modules.Context.ts';

    const dsLegalEntities = getOrCreateDataObject({
        id: 'dsLegalEntities' + crypto.randomUUID(),
        viewName: 'atbv_Accounts_LegalEntities',
        uniqueTable: null,
        selectFirstRowOnLoad: true,
        distinctRows: false,
        maxRecords: 1,
        allowInsert: false,
        allowUpdate: false,
        allowDelete: false,
        fields: [
            { name: "PrimKey", type: "string" },
            { name: "ID", type: "Number" },
            { name: "CompanyName", type: "string" }
        ]
    });

    const dsPersonLkp = getOrCreateDataObject({
        id: 'dsPersonLkp' + crypto.randomUUID(),
        viewName: 'aviw_Jobs_Persons',
        uniqueTable: null,
        distinctRows: false,
        selectFirstRowOnLoad: true,
        maxRecords: 50,
        allowInsert: false,
        allowUpdate: false,
        allowDelete: false,
        fields: [
            { name: "PrimKey", type: "string" },
            { name: "ID", type: "Number" },
            { name: "Created", type: "date"},
            { name: "CreatedBy_ID", type: "Number"},
            { name: "Updated", type: "date"},
            { name: "UpdatedBy_ID", type: "Number"},
            { name: "FirstName", type: "string", sortOrder: 2, sortDirection: "asc"},
            { name: "LastName", type: "string", sortOrder: 1, sortDirection: "asc"},
            { name: "Name", type: "string"},
            { name: "MobileNo", type: "Number"},
            { name: "MarketMakerLink", type: "string"},
            { name: "Represents", type: "string"}
        ],
        whereClause: "AssignmentLikelihood IN ('L0','L1','L2')"
    });  
    dsLegalEntities.enableContextFilter((path, id)=>{
        return `ID = ${id}`;
    });

    const procAddCostCentre = getOrCreateProcedure(
        {
            id: 'procAddCostCentre' + crypto.randomUUID, 
            procedureName: 'astp_Accounts_AddCostCentres'
        }
    )
    const emit = defineEmits(["CostCentreCreated"]);

    const modal = ref(null);
    const isLoading = ref(false);
    const legalEntity = ref({id:null, name:null});
    const person = ref({id:null, name:null, ccName:null});

    const openModal = () => {
        modal.value.show();
    };
    const hideModal = () =>{
        modal.value.hide();
    }
    defineExpose({ openModal, hideModal });

    onMounted(()=>{
        dsLegalEntities.load();
    });

    function createNewCostCentre(){
        isLoading.value = true;
        procAddCostCentre.execute({
            Person_ID: person.value.id,
            Name: person.value.ccName,
            LegalEntity_ID: legalEntity.value.id
        }).then((pData)=>{
            emit("CostCentreCreated", pData.Table[0].ID)
            isLoading.value = false;
        }).catch(()=>{
            isLoading.value = false;
        })
    }

    function updateModalFields(){
        legalEntity.value.id = context.id;
        legalEntity.value.name = context.orgUnit;
    }

    function clearAllFields(){
        legalEntity.value.id = null;
        legalEntity.value.name = null;
        person.value.id = null;
        person.value.name = null;
        person.value.ccName = null;
    }
</script>